import React, { Component } from 'react';
import logo from './Imgs/logo.svg';
import red_light from './Imgs/red-light.png';
import carro_icon from './Imgs/carro-icon.svg';
import bebida_icon from './Imgs/bebida-icon.svg';
import tlm_icon from './Imgs/tlm-icon.svg';
import outras_infracoes_icon from './Imgs/outras-infracoes-icon.svg';
import barra_cima_png from './Imgs/barra_cima_png.png';
import livro from './Imgs/livro.png';
import servicos_item_back from './Imgs/servicos_item_back.svg';
import servicos_fale from './Imgs/servicos-fale.svg';
import servicos_1 from './Imgs/servicos-1.svg';
import servicos_2 from './Imgs/servicos-2.svg';
import servicos_3 from './Imgs/servicos-3.svg';
import servicos_barra_f from './Imgs/servicos-barra-f.svg';
import address_img from './Imgs/address-img.svg';
import email_img from './Imgs/email-img.svg';
import phone_img from './Imgs/phone-img.svg';
import livro_modal from './Imgs/livro_modal.jpg';
import axios from 'axios';
import BeatLoader from 'react-spinners/BeatLoader';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faWhatsapp } from '@fortawesome/free-brands-svg-icons' 

class App extends Component {
  
  constructor(){
    super();

    this.state = {
      name: "",
      email:"",
      contacto:"",
      checked: false,
      message: "",
      file:"",
      filename:"",

      errorSubmitCheck: false,
      errorSubmitName: false,
      errorSubmitContacto: false,
      errorSubmitEmail: false,

      sendingEmail: false,
      emailSent: false
    }

    
    ReactGA.initialize('UA-121172839-5');
    ReactGA.pageview('/');
  }

    handleChange = e =>{
      this.setState({[e.target.name]:e.target.value})

      if(e.target.name === "name" && e.target.value !== 0)
        this.setState({ errorSubmitName: false });

        if(e.target.name === "contacto" && e.target.value !== 0)
        this.setState({ errorSubmitContacto: false });

        if(e.target.name === "email" && e.target.value !== 0)
        this.setState({ errorSubmitEmail: false });
    }

    handleChangeFile = e =>{
      this.setState({file:e.target.files[0]})
      this.setState({filename:e.target.value.replace("C:\\fakepath\\", "")})
    }

    handleCheckClick =  () => {
      this.setState({ checked: !this.state.checked });
      if(this.state.checked) this.setState({ errorSubmitCheck: false });
    }

    handleSubmitionClick = (e) => {
      e.preventDefault();

      if(!this.state.checked) this.setState({ errorSubmitCheck: true });
      else this.setState({ errorSubmitCheck: false });
      
      if(this.state.name.length === 0) this.setState({ errorSubmitName: true });
      else this.setState({ errorSubmitName: false });

      if(this.state.contacto.length === 0) this.setState({ errorSubmitContacto: true });
      else this.setState({ errorSubmitContacto: false });

      if(this.state.email.length === 0) this.setState({ errorSubmitEmail: true });
      else this.setState({ errorSubmitEmail: false });

      if(!this.state.checked || this.state.name.length === 0 || this.state.contacto.length === 0 || this.state.email.length === 0 || this.state.sendingEmail)
        return;


      this.setState({ sendingEmail: true });//activate loader

      const { name, email, contacto, message, file, filename} = this.state;

      const url = 'https://www.coimas.pt/mailer/mailer.php';
      const formData = new FormData();
      formData.append('file',file);
      formData.append('filename',filename);
      formData.append('name', name);
      formData.append('email', email);
      formData.append('contacto', contacto);
      formData.append('message', message);
      const config = {
          headers: {
              'content-type': 'multipart/form-data'
          }
      }
      
      axios.post(url, formData,config).then((response)=>{
        console.log(response.data);
        this.confirmationMsg();
        this.setState({ sendingEmail: false, name: "", email:"",contacto:"",checked: false,message: "",file:"",filename:"" });//desactivate loader
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    confirmationMsg = () => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui-modal'>
              <h1>A sua mensagem foi enviada com sucesso!</h1>
              <p className='center'>Entraremos em contacto o mais breve possível</p>
              <button onClick={onClose}>OK</button>
            </div>
          );
        }
      });
    };

    modalPoliticaPriv = () => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui-modal'>
          
              <div className="button-modal-sticky">
                <button className="buttonsair" onClick={onClose}>SAIR</button>
              </div>
              
              <h1>POLÍTICA DE PRIVACIDADE</h1>
              <p>A Presente Política de Privacidade regula o tratamento dos dados pessoais dos utilizadores (“Utilizador”), recolhidos no âmbito da utilização do website www.coimas.pt (“Site”), enquanto entidade responsável pelo tratamento.</p>
              <p>A plataforma Coimas está empenhada em proteger a sua privacidade, mantendo um firme compromisso com os direitos dos titulares dos dados pessoais, actuando de acordo com as disposições constantes do Regulamento Geral sobre a Protecção de Dados (“RGPD”) e demais legislação aplicável.</p>
              <p>Nos termos do artigo 92.º do Estatuto da Ordem dos Advogados, informamos que os seus dados pessoais encontram-se ainda protegidos ao abrigo do segredo profissional.</p>
              <p>A disponibilização dos seus dados pessoais implica o conhecimento e aceitação das condições constantes na presente Política de Privacidade.</p>
              <a href="#ambito" className="lista">Âmbito</a>
              <a href="#dados-pessoais" className="lista">Dados Pessoais</a>
              <a href="#resp-trat" className="lista">Responsável pelo Tratamento</a>
              <a href="#finalidade" className="lista">Finalidade</a>
              <a href="#cat-da-pess" className="lista">Categorias de Dados Pessoais</a>
              <a href="#conse-da-pess" className="lista">Conservação de Dados Pessoais</a>
              <a href="#dire-ut" className="lista">Direitos do Utilizador</a>
              <a href="#subcontr" className="lista">Subcontratação</a>
              <a href="#segr" className="lista">Segurança</a>
              <a href="#inform" className="lista">Informações</a>
              <a href="#act-pol-priv" className="lista">Actualização da Política de Privacidade</a>

              <p id="ambito" className="bold">Âmbito</p>
              <p>A presente Política de Privacidade aplica-se única e exclusivamente à recolha e tratamento de dados pessoais que é efectuada pela plataforma Coimas.</p>
              <p>O acesso e utilização do Site não implicam a disponibilização de dados pessoais pelo Utilizador, mas a utilização de determinadas funcionalidades implica a disponibilização de dados pessoais pelo Utilizador.</p>
              <p id="dados-pessoais" className="bold">Dados Pessoais</p>
              <p>Dados pessoais são a informação, de qualquer natureza e suporte, relativa a uma pessoa singular identificada ou identificável. É considerada como identificável uma pessoa que possa ser identificada, directa ou indirectamente, designadamente por referência a um número de identificação ou a um ou mais elementos específicos da sua identidade física, fisiológica, psíquica, económica, cultural ou social.</p>
              <p id="resp-trat" className="bold">Responsável pelo Tratamento</p>
              <p>A plataforma Coimas é a entidade responsável pela recolha e tratamento dos dados pessoais, obrigando-se a usar os mesmos de acordo com as normas legais aplicáveis e unicamente para as finalidades descritas na presente Política de Privacidade.</p>
              <p id="finalidade" className="bold">Finalidade</p>
              <p>Para a finalidade de responder às suas solicitações ou pedidos de esclarecimento (formulário “Fale Connosco”) procedemos ao tratamento dos seus dados pessoais tendo como fundamento jurídico o nosso interesse legítimo em prestar-lhe um serviço personalizado e, caso seja aplicável, a prossecução das necessárias diligências pré-contratuais a pedido do titular dos dados pessoais.</p>
              <p>Recolhemos o mínimo de informações necessárias para responder ao seu pedido, podendo ser solicitadas informações adicionais para prestar uma resposta mais adequada aos serviços disponibilizados.</p>
              <p>Indicamos o tipo de informação de preenchimento voluntário ou obrigatório.</p>
              <p id="cat-da-pess" className="bold">Categorias de Dados Pessoais</p>
              <p>Quando nos comunica dados pessoais através do formulário disponível no nosso Site ou através de qualquer outro meio de comunicação, iremos apenas proceder ao tratamento de dados de identificação necessários ao seu pedido, conforme descrito na presente Política de Privacidade.</p>
              <p>A plataforma Coimas recolhe os dados pessoais (nome, e-mail, número de telefone ou morada) expressamente fornecidos pelo Utilizador, mediante o seu consentimento, sendo esses dados tratados para pedido de contacto, de forma a prestar esclarecimentos e informações solicitadas pelo Utilizador.</p>
              <p>Quando visita este Site, podemos recolher informações técnicas, como o seu endereço IP, detalhes das páginas que visita no Site, outras páginas que visita na internet e qual o navegador que usou para visualizar o Site. Para mais informações pode consultar a nossa Política de Cookies.</p>
              <p id="conse-da-pess" className="bold">Conservação Dados Pessoais</p>
              <p>Os dados pessoais recolhidos são tratados no estrito cumprimento da legislação aplicável.</p>
              <p>Os dados pessoais serão conservados pelo período de tempo necessário de acordo com as respectivas finalidades definidas nesta Política de Privacidade e respeitando os prazos exigidos pelas normas legais ou regulamentos aplicáveis. </p>
              <p>Sempre que não exista uma exigência legal específica, os dados serão armazenados e conservados apenas pelo período adequado e na medida do necessário às finalidades para as quais foram recolhidos, excepto se o titular dos dados, dentro dos limites legais, exercer o direito de oposição, direito ao apagamento ou caso retire o seu consentimento.</p>
              <p>Depois de decorrido o respectivo período de conservação, os dados serão eliminados sempre que os mesmos não devam ser conservados para finalidade distinta que possa subsistir.</p>
              <p id="dire-ut" className="bold">Direitos do Utilizador</p>
              <p>Nos termos da legislação aplicável, o titular dos dados poderá solicitar, a todo o tempo, o acesso aos seus dados pessoais recolhidos, bem como requerer a sua rectificação, eliminação, limitação do seu tratamento, portabilidade dos seus dados ou opor-se ao seu tratamento.</p>
              <p className="bold avanco">  &bull; Direito de Acesso</p>
              <p>A qualquer momento, o Utilizador do Site poderá solicitar o acesso aos seus dados pessoais. Poderá ainda obter cópia dos dados pessoais sujeitos a tratamento.</p>
              <p className="bold avanco">  &bull; Direito à Rectificação</p>
              <p>Caso os seus dados pessoais estejam incorrectos ou incompletos, pode solicitar que os mesmos sejam rectificados ou completados.</p>
              <p className="bold avanco">  &bull; Direito ao Apagamento</p>
              <p>Em determinadas situações, tem o direito de solicitar o apagamento dos seus dados pessoais. Este direito pode ser limitado quando o tratamento dos dados for necessário ao cumprimento de obrigações legais ou quando esse tratamento for necessário para efeitos de declaração, exercício ou defesa de um direito num processo judicial.</p>
              <p className="bold avanco">  &bull; Direito à limitação do tratamento</p>
              <p>Em certas situações, o titular dos dados pode solicitar a limitação ao acesso a dados pessoais ou que suspenda as actividades de tratamento.</p>
              <p className="bold avanco">  &bull; Portabilidade dos Dados Pessoais</p>
              <p>Nos casos em que o tratamento dos dados se basear num contrato em que seja parte ou no seu consentimento, pode o titular dos dados solicitar que lhe sejam entregues os dados que tenham sido recolhidos. Pode ainda solicitar, desde que tal seja tecnicamente possível, que transmita esses dados a outros responsáveis pelo tratamento.</p>
              <p className="bold avanco">  &bull; Direito de Oposição</p>
              <p>Quando o tratamento dos dados tenha por base um interesse legítimo ou quando o mesmo for realizado para fins diversos daqueles para os quais os dados foram recolhidos, mas que sejam compatíveis com os mesmos, o titular dos dados tem o direito de se opor ao tratamento dos seus dados pessoais.</p>
              <p className="bold avanco">  &bull; Retirar o Consentimento</p>
              <p>Nos casos em que o tratamento dos dados seja realizado com base no seu consentimento, a qualquer momento, o titular dos dados poderá retirar o consentimento. Caso retire o seu consentimento, os seus dados pessoais deixarão de ser tratados, excepto se existir outro fundamento jurídico que permita esse tratamento.</p>
              <p>O titular dos dados pode exercer os seus direitos, a qualquer momento e de forma gratuita, através de qualquer um dos seguintes meios:</p>
              <p className="bold">Morada</p>
              <p>Rua 1.º de Dezembro, n.º 38, 1.º andar, 8100-615 Loulé, Portugal</p>
              <p className="bold">E-mail</p>
              <p><a href="mailto:geral@coimas.pt">geral@coimas.pt</a></p>
              <p className="bold">Telefone</p>
              <p>(+351) 289 035 906</p>
              <p id="subcontr" className="bold">Subcontratação</p>
              <p>Não transmitimos os seus dados pessoais a terceiros, excepto nos casos em que tal se revele necessário à prestação dos serviços que tenha solicitado ou ao cumprimento de obrigações legais.</p>
              <p>Em caso de necessidade de subcontratação, a transmissão de dados a terceiros é realizada de acordo com a legislação aplicável em matéria de protecção de dados e dentro dos limites das finalidades e fundamentos jurídicos definidos na presente Política de Privacidade. Em qualquer das situações mencionadas, garantimos sempre que os dados pessoais apenas serão fornecidos para as finalidades que foram recolhidos e que os subcontratantes cumprem todas as regras aplicáveis à protecção dos dados pessoais.</p>
              <p>Eventualmente, e em resposta a uma ordem judicial, intimação, investigação do governo ou, de outra forma, exigida ou permitida por lei, podemos divulgar os seus dados pessoais.</p>
              <p>Não disponibilizamos informações a terceiros para os seus próprios fins de marketing e não realizamos operações de marketing para terceiros.</p>
              <p id="segr" className="bold">Segurança</p>
              <p>Garantimos uma política de segurança rigorosa, do ponto de vista técnico e organizacional, de forma a proteger os dados pessoais dos Utilizadores contra acessos não autorizados.</p>
              <p>Utilizamos sistemas de segurança de modo a garantir a protecção dos dados pessoais contra o acesso não autorizado aos dados, o uso ilegítimo, a sua divulgação, perda ou destruição.</p>
              <p>Apenas o pessoal autorizado e vinculado ao dever de sigilo e confidencialidade pode ter acesso aos dados pessoais.</p>
              <p id="inform" className="bold">Informações</p>
              <p>Para qualquer questão relacionada com o tratamento dos seus dados, pode contactar-nos através de um dos seguintes meios:</p>
              <p className="bold">Morada</p>
              <p>Rua 1.º de Dezembro, n.º 38, 1.º andar, 8100-615 Loulé, Portugal</p>
              <p className="bold">E-mail</p>
              <p><a href="mailto:geral@coimas.pt">geral@coimas.pt</a></p>
              <p className="bold">Telefone</p>
              <p>(+351) 289 035 906</p>
              <p>Se tem alguma reserva sobre matérias relacionadas com o tratamento dos seus dados pessoais, tem o direito de nos apresentar uma reclamação ou directamente junto da autoridade de controlo competente: CNPD - Comissão Nacional de Proteção de Dados, através do site <a href="https://www.cnpd.pt">www.cnpd.pt.</a></p>
              <p id="act-pol-priv" className="bold">Actualização da Política de Privacidade</p>
              <p>A presente Política de Privacidade, actualizada de acordo com o Regulamento Geral sobre a Protecção de Dados (“RGPD”) e demais legislação aplicável, poderá ser alterada ou actualizada, a qualquer momento, quando se mostrar necessário, sendo as alterações efectuadas devidamente publicitadas.</p>

              <button onClick={onClose}>OK</button>
            </div>
          );
        }
      });
    };

    modalPoliticaCookies = () => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui-modal'>
              <div className="button-modal-sticky">
                <button className="buttonsair" onClick={onClose}>SAIR</button>
              </div>

              <h1>POLÍTICA DE COOKIES</h1>
              <p className="bold">O que são cookies?</p>
              <p>Os cookies são pequenos ficheiros de texto que são armazenados no computador ou no dispositivo móvel através do navegador de internet (browser), com o objectivo de melhorar a sua experiência de navegação.</p>
              <p>Os cookies não identificam o utilizador do Site (“Utilizador”), mas apenas o computador ou dispositivo móvel utilizado. </p>
              <p>Os cookies não são usados para recolher dados pessoais.</p>
              <p className="bold">Utilização de cookies</p>
              <p>Este Site poderá recorrer à utilização de cookies, mediante o consentimento prévio e expresso, que servem para ajudar a melhorar a experiência de utilização, permitindo uma navegação mais rápida e eficiente. Os cookies são utilizados para prestar um serviço mais personalizado e à medida dos Utilizadores.</p>
              <p>O Site guarda e obtém informação do seu navegador, através do uso de cookies. Esta informação é utilizada para oferecer uma experiência mais individualizada.</p>
              <p>Este Site compromete-se a utilizar o armazenamento de cookies apenas para as finalidades acima descritas. </p>
              <p className="bold">Controlo de cookies</p>
              <p>Todos os navegadores da Web (browsers) permitem ao Utilizador exercer o controlo de cookies armazenados através das configurações do navegador. A qualquer momento, o Utilizador tem a possibilidade de aceitar, recusar ou eliminar cookies, através das configurações do respectivo navegador.</p>
              <p>Ao desactivar o uso de cookies o Utilizador poderá deixar de ter acesso a funcionalidades, de receber informação personalizada ou afectar, parcial ou totalmente, a sua experiência de navegação no Site.</p>
              <p className="bold">Lista de cookies</p>
              <p>Os cookies que utilizamos:</p>
              <p>Google Analytics (http://google.com/policies/privacy/partners)</p>
              <p className="bold">Alterações à Política de Cookies</p>
              <p>A plataforma Coimas reserva-se no direito de, a qualquer momento, alterar a presente Política de Cookies. Essas alterações serão devidamente publicitadas no Site.</p>
              <p className="bold">Consentimento</p>
              <p>Ao utilizar este Site, concorda com o uso de cookies nas condições referidas na presente Política de Cookies.</p>
              <button onClick={onClose}>OK</button>
            </div>
          );
        }
      });
    };

    modalTermosCondicoes = () => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui-modal'>

              <div className="button-modal-sticky">
                <button className="buttonsair" onClick={onClose}>SAIR</button>
              </div>
              <h1>TERMOS E CONDIÇÕES</h1>
              <p>A utilização do presente Site rege-se pelos presentes Termos e Condições.</p>
              <p>O utilizador do Site (“Utilizador”) reconhece que ao usar este Site está a aceitar estes Termos e Condições.</p>
              <p className="bold">Objecto do Site</p>
              <p>Este Site tem como finalidade exclusiva o fornecimento de informações gerais sobre a plataforma Coimas e a sua actividade. </p>
              <p>Nenhum conteúdo deste Site pode ser considerado como um meio para estabelecer relações de natureza profissional ou para prestar serviços jurídicos, de qualquer natureza.</p>
              <p>Nada do que se encontra disposto neste Site pode ser entendido como aconselhamento jurídico nem estabelece uma relação de advogado-cliente ou com qualquer dos seus advogados. </p>
              <p className="bold">Acesso ao Site</p>
              <p>O direito de acesso ao Site pode ser suspenso, parcial ou totalmente, em especial nas operações de gestão, manutenção, reparação, alteração ou modernização e encerrar, definitiva ou temporariamente, parcial ou totalmente, a todo o tempo.</p>
              <p className="bold">Condições de Uso</p>
              <p>O Utilizador obriga-se a fazer um uso correto do Site, em conformidade com a lei e os presentes Termos e Condições. </p>
              <p>O Utilizador responderá por quaisquer danos e prejuízos que possam ser causados devido ao incumprimento das referidas condições.</p>
              <p className="bold">Direitos de Propriedade Intelectual</p>
              <p>O Utilizador reconhece que os conteúdos deste Site estão protegidos por direitos de propriedade intelectual e obriga-se a respeitar tais direitos.</p>
              <p>Os direitos sobre textos, imagens, gráficos, som, animação e todas as outras informações e a forma em que são representadas e dispostas graficamente no Site, incluindo as marcas, logótipos e símbolos, estão cobertas e protegidas por direitos de propriedade intelectual. </p>
              <p>O Utilizador não está autorizado a transmitir, publicar, modificar, copiar, vender, utilizar ou distribuir, por qualquer forma, os textos, imagens, marcas e logótipos ou outras informações existentes neste Site, salvo nos casos legalmente autorizados ou expressamente consentidos por escrito.</p>
              <p>O incumprimento do que aqui se estabelece em relação à informação e à utilização dos objectos de propriedade intelectual exibidos ou de outro modo disponibilizados no Site constitui uma violação dos presentes Termos e Condições.</p>
              <p className="bold">Tratamento de Dados Pessoais</p>
              <p>A navegação através do presente Site não implica, necessariamente, o fornecimento de dados pessoais. </p>
              <p>A utilização de certos serviços disponibilizados no Site, no entanto, está condicionada ao fornecimento de dados pessoais do Utilizador. O preenchimento do formulário de contacto (formulário “Fale Connosco”), através do presente Site, implica o fornecimento de dados pessoais, sendo os mesmos utilizados nos termos previstos nos respectivos formulários. </p>
              <p>Para mais informações sobre o tratamento de dados pessoais consulte a Política de Privacidade.</p>
              <p className="bold">Informações</p>
              <p>A informação disponibilizada no Site visa essencialmente esclarecer e informar os Utilizadores sobre a actividade da plataforma. As informações prestadas têm um carácter meramente indicativo e informador.</p>
              <p className="bold">Exclusão de Responsabilidade</p>
              <p>Os conteúdos informativos do Site são de carácter geral e não constituem, de modo algum, a prestação de um serviço de assessoria legal ou fiscal de qualquer tipo, pelo que a referida informação é insuficiente para a tomada de decisões por parte do Utilizador.</p>
              <p>A plataforma Coimas não é responsável por quaisquer danos e/ou omissões causados ao Utilizador ou a terceiros, decorrentes da utilização ou divulgação das informações contidas no Site.</p>
              <p>A plataforma Coimas não é responsável por erros que possam ocorrer devido a irregularidades do sistema ou falha (temporária ou permanente) do Site, nem será responsável por quaisquer danos resultantes da utilização indevida ou da impossibilidade de utilização do Site.</p>
              <p className="bold">Validade dos Termos e Condições</p>
              <p>Se alguma parte ou disposição dos presentes Termos e Condições não for executável ou estiver em conflito com a lei aplicável, a validade das restantes partes ou disposições não será afectada.</p>
              <p className="bold">Alteração dos Termos e Condições</p>
              <p>O Site reserva-se no direito de introduzir alterações, aditamentos, eliminar, parcial ou totalmente, os presentes Termos e Condições, em qualquer momento e sem aviso prévio, colocando para o efeito os novos Termos e Condições no Site.</p>
              <p>O Utilizador deve consultar periodicamente as actualizações dos Termos e Condições do Site.</p>
              <p className="bold">Questões e Sugestões</p>
              <p>Se tiver alguma questão sobre os presentes Termos e Condições, por favor envie-nos o seu pedido para um dos seguintes meios:</p>
              <p className="bold">Morada</p>
              <p>Rua 1.º de Dezembro, n.º 38, 1.º andar, 8100-615 Loulé, Portugal</p>
              <p className="bold">E-mail</p>
              <p><a href="mailto:geral@coimas.pt">geral@coimas.pt</a></p>
              <p className="bold">Telefone</p>
              <p>(+351) 289 035 906</p>
              <p className="bold">Lei aplicável</p>
              <p>Os presentes Termos e Condições encontram-se sujeitos à lei portuguesa.</p>
              <button onClick={onClose}>OK</button>
            </div>
          );
        }
      });
    };

    modalBook = () => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui-modal'>

            <div className="livro-modal-container">
              <div className="livro-modal">
                <img alt="" src={livro_modal}></img>
              </div>

              <div className="resumo-livro-modal-container">
                <p className="title">A obra CONTRA-ORDENAÇÕES RODOVIÁRIAS, publicada pela Editora Almedina, é um guia teórico-prático da nossa co-autoria, que procura ir ao encontro do trabalho desenvolvido pela plataforma Coimas.</p>
                <p className="sinopse">Sinopse</p>
                <p>Considerando o elevado número de contra-ordenações ao Código da Estrada, são inquestionáveis as consequências práticas que a aplicação de uma coima ou sanção acessória implica para o condutor.</p>
                <p>A presente obra encontra-se estruturada em duas partes fundamentais: a primeira, inteiramente teórica, dedicada ao enquadramento do processo contra-ordenacional rodoviário, abordando os procedimentos a adoptar e os prazos a cumprir (quer na fase administrativa, quer na fase judicial); e a segunda, essencialmente prática, onde são apresentados diversos formulários de apoio, designadamente, requerimentos, defesas, impugnações judiciais e recursos de infracções ao Código da Estrada.</p>
                <p>Trata-se, por isso, de uma obra transversal a todo o regime das contra-ordenações rodoviárias.</p>
              </div>
            </div>

              <button onClick={onClose}>SAIR</button>
            </div>
          );
        }
      });
    };

  render() {
    return (
        <div className="container">

      <a href="#FALE"></a>
      <a href="https://wa.me/351917053863" className="float" target="_blank">
        <FontAwesomeIcon icon={faWhatsapp} className="my-float" size ="lg" color ="#FFFFFF"/>
      </a>

        <CookieConsent   buttonText="Aceitar" buttonStyle={{ color: "#FFFFFF", fontSize: "13px", backgroundColor: "#00add3"}}>
            Este website usa Cookies. <span className="cookieconsent" onClick= {this.modalPoliticaCookies}>Política de Cookies</span>
        </CookieConsent>

          <div className="bg-image">
            <div className="top-container">

              <div className="titles-container">
                <div className="container-logo">
                  <img alt="" src={logo} className="logo"/>
                </div>
                
                <div className="container-options">
                  {/* <a className="option" href="#HOME">HOME</a> */}
                  <a className="option" href="#SOBRE">SOBRE NÓS</a>
                  <a className="option" href="#SERVICOS">SERVIÇOS</a>
                  <a className="option" href="#FALE">FALE CONNOSCO</a>
                  <a className="option" href="#CONTACTOS">CONTACTOS</a>
                </div>
              </div>
              
              <div className="tipo-coimas-container">
                <div className="tipo-coimas-item">
                  <img alt="" src={red_light} className="tipo-coimas-item-img-cima"/>
                  <span>EXCESSO VELOCIDADE</span>
                  <img alt="" src={carro_icon} className="tipo-coimas-item-img-baixo"/>
                </div>

                <div className="tipo-coimas-item">
                  <img alt="" src={red_light} className="tipo-coimas-item-img-cima"/>
                  <span>TELEMÓVEL</span>
                  <img alt="" src={tlm_icon} className="tipo-coimas-item-img-baixo"/>
                </div>

                <div className="tipo-coimas-item">
                  <img alt="" src={red_light} className="tipo-coimas-item-img-cima"/>
                  <span>EXCESSO ÁLCOOL</span>
                  <img alt="" src={bebida_icon} className="tipo-coimas-item-img-baixo"/>
                </div>

                <div className="tipo-coimas-item">
                  <img alt="" src={red_light} className="tipo-coimas-item-img-cima"/>
                  <span>OUTRAS INFRACÇÕES</span>
                  <img alt="" src={outras_infracoes_icon} className="tipo-coimas-item-img-baixo"/>
                </div>
              
              </div>

              <div className="barra-cima-container">
                <img alt="" src={barra_cima_png} className="fundo"/>
                <a href="#FALE"></a>
                
                
                {/* <div className="barra-cima-container-inside">
                  <div className="barra-cima-container-inside2">
                    <div className= "left">
                      <span className= "top">CONTESTE AS SUAS COIMAS!</span>
                      <span className= "bottom">Evite coimas, sanções acessórias, a perda de pontos ou ficar sem carta de condução</span>
                    </div>

                    <div className= "right">
                      <img alt="" src={barra_cima_direita} className="fundo-d"/>
                      <a href="#FALE">FALE CONNOSCO!</a>
                    </div>
                  </div>
                </div>
                 */}
              </div>
            
            </div>
          </div>

          <div id="SOBRE" className ="sobre-nos">
            <div className="sobre-nos-left">
              <div className="sobre-nos-left-t">
                <span>Bem-vindo ao Coimas</span>
              </div>
            </div>
            <div className="sobre-nos-right">
              <p className="line-right"></p>
              <p className = "text">Coimas é uma plataforma autónoma, composta por advogados multidisciplinares, exclusivamente dedicada ao Direito Contra-Ordenacional, com especial relevância no Direito Rodoviário.</p>
              <p className = "text">Com elevados padrões de exigência, prestamos um serviço especializado na defesa e impugnação de contra-ordenações nas mais diversas áreas do Direito.</p>
              <p className = "text">Focados em prestar um serviço distinto e personalizado, somos uma equipa determinada em assegurar a defesa dos direitos dos nossos Clientes.</p>
            </div>
          </div>

          <div className ="servicos-esp">

            <div className="servicos-esp-left">
              <p className = "line-left"></p>
              <p className = "title">Composta por uma equipa de advogados dinâmica, prestamos um serviço especializado no ramo do Direito Contra-Ordenacional, com predomínio no Direito Rodoviário, nomeadamente na defesa dos seus direitos no âmbito de:</p>
              <p className = "text">&bull; Infracções Rodoviárias (ANSR, IMT, entre outras)</p>
              <p className = "text">&bull; Infracções Ambientais</p>
              <p className = "text">&bull; Infracções Laborais (ACT)</p>
              <p className = "text">&bull; Infracções Tributárias</p>
              <p className = "text">&bull; Infracções Fiscais</p>
              <p className = "text">&bull; Infracções Económicas (ASAE)</p>
            </div>

            <div className="servicos-esp-right">
              <div className="servicos-esp-right-t">
                <span>Serviço Especializado</span>
              </div>
            </div>

          </div>

          <div className ="servicos-esp-baixo">
            <div className = "item"> 
              <p className="title">
                Compromisso
              </p>
              <p className="body">
                Prestamos um serviço apoiado no rigor profissional, na prontidão das respostas e na apresentação de soluções inovadoras.
              </p>
            </div>
            <div className = "item"> 
              <p className="title">
                Ética Profissional
              </p>
              <p className="body">
                Assentamos a nossa actuação no cumprimento rigoroso dos deveres éticos e profissionais do Direito e no respeito pelo segredo profissional.              
              </p>
            </div>
            <div className = "item"> 
              <p className="title">
                Independência
              </p>
              <p className="body">
                Constituída por uma equipa de advogados inteiramente independente e capaz de dar resposta eficaz às necessidades de cada Cliente.
              </p>
            </div>
            <div className = "item"> 
              <p className="title">
                Valores
              </p>
              <div className="body">
                <p>
                  A nossa motivação é a procura da excelência e eficácia de modo a responder às necessidades de cada Cliente na defesa dos seus direitos.
                </p>
                <p className="option-body">
                  - Dedicação
                </p>
                <p className="option-body">
                  - Inovação
                </p>
                <p className="option-body">
                - Competência
                </p>   
                <p className="option-body">
                - Rigor
                </p>         
              </div>
            </div>
          
          </div>

          <div className="livro-container">

            <div className="livro" onClick= {this.modalBook}>
              <img alt="" src={livro}></img>
            </div>

            <div className="livro-container-inside">
              <div className="title">CONTRA-ORDENAÇÕES RODOVIÁRIAS</div>
              {/* <div className="middle">Livro de Indalécio Sousa e Cíntia Andrade</div> */}
              <div className="bottom">Regime das contra-ordenações estradais, requerimentos, defesas, impugnações judiciais e recursos de infracções ao código da estrada</div>
            </div>
          </div>

          <div id="SERVICOS" className="servicos-container">
            <div className="servicos-container-trans">
            <div className="title-servicos">
                <div className="linha"></div>
                <div className="title">SERVIÇOS</div>
              </div>

              <div className="itens-container">
                <div className="item">
                  <div className= "imagem-cima">
                    <img alt="" src={servicos_item_back}/>
                    <span>BÁSICO</span>
                  </div>
                  <div className= "texto-body">
                    <p>Defesa</p>
                    <p>ou</p>
                    <p>Impugnação Judicial</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                  </div>
                  <div className= "imagem-baixo">
                    <img alt="" src={servicos_fale}/>
                    <a href="#FALE">FALE CONNOSCO</a>
                  </div>
                </div>

                <div className="item">
                  <div className= "imagem-cima">
                    <img alt="" src={servicos_item_back}/>
                    <span>AVANÇADO</span>
                  </div>
                  <div className= "texto-body">
                    <p>Defesa</p>
                    <p>Impugnação Judicial</p>
                    <p>+</p>
                    <p>Acompanhamento</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                  </div>
                  <div className= "imagem-baixo">
                    <img alt="" src={servicos_fale}/>
                    <a href="#FALE">FALE CONNOSCO</a>
                  </div>
                </div>

                <div className="item">
                  <div className= "imagem-cima">
                    <img alt="" src={servicos_item_back}/>
                    <span>PREMIUM</span>
                  </div>
                  <div className= "texto-body">
                    <p>Defesa</p>
                    <p>Impugnação Judicial</p>
                    <p>Acompanhamento</p>
                    <p>+</p>
                    <p>Julgamento</p>
                    <p>Recurso</p>
                    <p>&nbsp;</p>
                  </div>
                  <div className= "imagem-baixo">
                    <img alt="" src={servicos_fale}/>
                    <a href="#FALE">FALE CONNOSCO</a>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="servicos-baixo-container">
            <div className="servicos-baixo-container2">
              <div className="item-container">
                  <div className="item">
                    <img alt="" src={servicos_1}/>
                    <div className="text">Envie-nos o(s) auto(s) de contra-ordenação.</div>
                    <div className="number">1</div>
                  </div>
                  <div className="item barra">
                    <img alt="" src={servicos_barra_f}/>
                  </div>
                  <div className="item">
                    <img alt="" src={servicos_2}/>
                    <div className="text">Análise do processo por um dos nossos advogados.<br/>Proposta de defesa ou impugnação judicial.</div>
                    <div className="number">2</div>
                  </div>
                  <div className="item barra">
                    <img alt="" src={servicos_barra_f}/>
                  </div>
                  <div className="item">
                    <img alt="" src={servicos_3}/>
                    <div className="text">Elaboramos a sua defesa e contestamos as suas multas.</div>
                    <div className="number">3</div>
                  </div>
                  <div className="item barra">
                    <img alt="" src={servicos_barra_f}/>
                  </div>

                  {/* <img className="img-barra" src={servicos_barra}/> */}

                </div>
                <p>ENTRE AGORA EM CONTACTO E RECEBA UMA SOLUÇÃO PARA A SUA SITUAÇÃO</p>
              </div>
            </div>

          <div id="FALE" className="footer-container">
            <div className="form-area-container">

              <div className="left">
                  <p className="title">FALE CONNOSCO</p>
                  <form>
                    <input  value={this.state.name} className={this.state.errorSubmitName ? "input-error-not-filled input-text" : "input-filled input-text"} onChange={this.handleChange} type="text" name="name" placeholder="Nome*"/>
                    <input value={this.state.contacto} className={this.state.errorSubmitContacto ? "input-error-not-filled input-text" : "input-filled input-text"} onChange={this.handleChange} type="tel" name="contacto" placeholder="Contacto*"/>
                    <input value={this.state.email} className={this.state.errorSubmitEmail ? "input-error-not-filled input-text" : "input-filled input-text"} onChange={this.handleChange} type="email" name="email" placeholder="Email*"/>
                    <textarea className="input-text" value={this.state.message} onChange={this.handleChange} name="message" placeholder="Mensagem" maxLength="450"/>
                    
                  </form>
                    <div className="flex-rodape-form">
                      <label className="file-upload">
                        <span>+ ADICIONAR Multa(s)</span>
                        <input onChange={this.handleChangeFile} type="file" name="file"/>
                      </label>

                      <p className="rodape-form">* campos de preenchimento obrigatório</p>
                    </div>
          
                    <p className="path">{this.state.filename}</p>
                    <p className="rodape-form-baixobotao">documento .pdf ou imagem até 2MB</p>

                    <div className="container-checkbox">
                      <label className="container-checkbox-label">
                        <input checked={this.state.checked} onChange={this.handleCheckClick} type="checkbox" name="checkbox" />
                        <span className={"checkmark " + ((this.state.errorSubmitCheck & !this.state.checked)? "checkmark-not-accepted" : "")}/>
                      </label>
                      <div className="text-checkbox">Li e aceito os termos e condições da <span  onClick= {this.modalPoliticaPriv}>Política de Privacidade</span></div>                   
                    </div>

                    <div className="loader-enviar-container">
                      <div className='loader'>
                        <BeatLoader
                          sizeUnit={"px"}
                          size={20}
                          color={'#FFFFFF'}
                          loading={this.state.sendingEmail}
                        />
                      </div>
                      <div onClick={this.handleSubmitionClick} className="text-enviar">ENVIAR</div>
                    </div>
                    

                </div>
              <div id="CONTACTOS" className="right">
                <div className="item">
                  <img alt="" src={address_img}/>
                  <p>Rua 1.º de Dezembro, n.º 38, 1.º andar</p>
                  <p>8100-615 Loulé, Portugal</p>
                </div>
                <div className="item">
                  <img alt="" src={email_img}/>
                  <p>geral@coimas.pt</p>
                </div>
                <div className="item">
                  <img alt="" src={phone_img}/>
                  <p>+351 289 035 906</p>
                </div>
                <div className="item">
                  <a href="https://www.facebook.com/CoimasAdvogados">
                    <FontAwesomeIcon icon={faFacebookF} size ="2x" color ="#FFFFFF"/>
                  </a>
                  
                    
                </div>
              </div>

              <div className="footer-rights">
                  <p onClick= {this.modalTermosCondicoes} className="item">TERMOS E CONDIÇÕES</p>                   
                  <p onClick= {this.modalPoliticaPriv} className="item">POLÍTICA DE PRIVACIDADE</p> 
                  <p onClick= {this.modalPoliticaCookies} className="item last">POLÍTICA DE COOKIES</p> 
              </div>
            </div>
          </div>

        </div>
    );
  }
}

export default App;
